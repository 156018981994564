import React from "react";
import styled from "styled-components";
import { darkPurple, mainWhite, transHover, screen } from "../variables";

const Wrapper = styled.div`
  button,
  a {
    all: unset;
    background: ${mainWhite};
    color: ${darkPurple};
    font-weight: 600;
    font-size: 1.05rem;
    padding: 8px 24px;
    line-height: 1.5;
    border-radius: 10px;
    cursor: pointer;
    transition: ${transHover};
    @media ${screen.xsmall} {
      font-size: 1.1rem;
      padding: 9px 26px;
    }
    @media ${screen.small} {
      font-size: 1.16rem;
    }
    @media ${screen.medium} {
      font-size: 1.23rem;
      padding: 10px 30px;
    }
  }
`;

const Button = ({ className, onClick, href, isNewTab, children }) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      {href ? (
        <a href={href} rel="noopener" target={isNewTab && "_blank"}>
          {children}
        </a>
      ) : (
        <button>{children}</button>
      )}
    </Wrapper>
  );
};

export default Button;
