import React from "react";
import styled from "styled-components";
import {
  darkPurple,
  subFont,
  mainWhite,
  moveBgColor,
  screen,
  mainPurple,
} from "../variables";
import Button from "../button";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;

  .subwrapper {
    display: flex;

    .col {
      padding: 55px 0;
      @media ${screen.xsmall} {
        padding: 100px 0;
      }

      @media ${screen.medium} {
        padding: 110px 0;
      }

      &--img {
        display: none;
        @media ${screen.xsmall} {
          background-image: ${(props) => `url(${props.bgImg})`};
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: block;
          width: 40%;
        }
        @media ${screen.small} {
          width: 45%;
        }

        @media ${screen.medium} {
          width: 50%;
        }
      }

      &--text {
        background-image: linear-gradient(120deg, #67b6ff, #3697ed, #6300ff);
        background-size: 310%;
        animation: ${moveBgColor} 5s infinite alternate;
        width: 100%;
        @media ${screen.xsmall} {
          width: 60%;
        }
        @media ${screen.small} {
          width: 55%;
        }

        @media ${screen.medium} {
          width: 50%;
        }

        .text-subwrapper {
          max-width: 600px;
          padding: 0 25px;
          position: relative;
          z-index: 3;
          @media ${screen.xsmall} {
            padding: 0 40px 0 40px;
          }
          @media ${screen.small} {
            padding: 0 40px 0 60px;
          }

          @media ${screen.medium} {
            padding: 0 0 0 80px;
          }
          @media ${screen.large} {
            padding: 0 0 0 100px;
          }

          .description {
            margin: 0 0 32px 0;

            h2,
            h3 {
              font-family: ${subFont};
              font-size: 1.55rem;
              color: ${darkPurple};
              line-height: 1.16;
              margin: 0 0 20px 0;
              @media ${screen.xsmall} {
                font-size: 2.25rem;
              }
              @media ${screen.small} {
                font-size: 2.35rem;
              }
              @media ${screen.medium} {
                font-size: 2.45rem;
              }

              strong {
                color: ${mainWhite};
              }
            }

            p {
              color: ${mainWhite};
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
              &:last-child {
                margin: 0;
              }
            }

            a {
              color: ${mainWhite};
              @media ${screen.withCursor} {
                &:hover {
                  color: ${darkPurple};
                }
              }
            }
          }

          .btn {
            button {
              @media ${screen.withCursor} {
                &:hover {
                  background: ${darkPurple};
                  color: ${mainWhite};
                }
              }
            }
          }
        }
      }
    }
  }

  .arrows-bg {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    pointer-events: none;
    width: 430px;
    @media ${screen.xsmall} {
      width: calc(550px + 7vw);
      right: 50px;
      left: auto;
    }
  }
`;

const CTA = ({
  className,
  html,
  bgImg,
  isTableauPage,
  setIsGeneralForm,
  setIsTableauForm,
}) => {
  const triggerForm = isTableauPage ? setIsTableauForm : setIsGeneralForm;

  return (
    <Wrapper bgImg={bgImg} className={className}>
      <div className="subwrapper">
        <div className="col col--img"></div>
        <div className="col col--text">
          <div
            className="text-subwrapper"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <Button className="btn" onClick={triggerForm}>
              {isTableauPage ? "START YOUR FREE TRIAL" : "GET IN TOUCH"}
            </Button>
          </div>
        </div>
      </div>

      <div className="arrows-bg" role="presentation">
        <StaticImage
          src="../../images/Service-Industry-Tableau-Banner-Arrows.png"
          placeholder="blurred"
          alt="Graphics"
        />
      </div>
    </Wrapper>
  );
};

export default CTA;
