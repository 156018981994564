import slugify from "slugify";

export const toSlug = (string) => {
  if (string) {
    return slugify(string, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
    });
  } else {
    return "";
  }
};

export const isOdd = (num) => {
  return num % 2;
};

export const getVimeoId = (url) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};
